import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { Link } from "gatsby";

function HeroSection({ data, isMobileView }) {
  const [slug, setSlug] = useState("");

  const handleClick = (val) => {
    setSlug(val);
    if (val === "getting-started") {
      window.scrollTo({ top: document.body.scrollHeight });
    } else {
      navigate(`/aws-public-support-statement/#${val}`, { replace: true });
    }
  };

  useEffect(() => {
    let selectedSlug = window?.location.href.split("#")[1];
    if (selectedSlug) {
      setSlug(selectedSlug);
    }
  });
  return (
    <div className="platform_upper_class">
      <div className="platformHeroSection">
        <div className="global-margin">
          <h4
            className={` ${
              isMobileView
                ? " light-blue-color uppercase  font-size-20 line-height-25"
                : "platformHeroSectionTitle "
            }`}
          >
            {data && data.HeroSection.title}
          </h4>
          <h1
            className={` ${
              isMobileView
                ? "font-size-20 line-height-25 roboto text-white "
                : " platformHeroSectionHeading"
            }`}
          >
            {data.HeroSection.heading1}{" "}
            <span className={` ${isMobileView ? "light-blue-color " : " "}`}>
              {data.HeroSection.heading2}
            </span>
          </h1>
          <div className="platformHeroSectionDescription">
            {data.HeroSection.des}
          </div>
          <div
            className={` ${
              isMobileView
                ? "opsBtnwrap middelBtn pb-0"
                : "opsBtnwrap middelBtn"
            }`}
          >
            <Link className="opsBtnlink" to={`/contact-us/`}>
              <p className="">{data.HeroSection.btn}</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="platformNavSection"></div>
    </div>
  );
}

export default HeroSection;
