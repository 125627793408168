import React, { useState, useEffect } from "react";
import WorkingWithUs from "../platform/workingWithUs";
import { Link } from "gatsby";
import one from "../../images/01.svg";
import two from "../../images/02.svg";
import three from "../../images/03.svg";
import four from "../../images/04.svg";
import five from "../../images/05.svg";

const WhyAws = ({ data, locale, isMobileView }) => {
  const images = [one, two, three, four, five];

  return (
    <div
      className={` ${
        isMobileView
          ? "solSection partnerHotspot pt-2"
          : "solSection partnerHotspot"
      }`}
      id="why-aws"
    >
      <div className="global-margin">
        <div className="solsecHead">
          <h5
            className={` ${
              isMobileView
                ? "awsTitle text-start my-4  text-center"
                : "awsTitle text-start my-4 "
            }`}
          >
            {data.title}
          </h5>
          <span
            className={` ${
              isMobileView
                ? "awsDescription text-center "
                : "awsDescription text-start "
            }`}
          >
            {data.description}
          </span>
        </div>
        <div className="solsecBody">
          <div className="solbodyWrap">
            <div className="partnercol">
              {data.bulletpoints.map((val, index) => (
                <div className="row my-4" key={index}>
                  <div
                    className={` ${
                      isMobileView
                        ? "col-12 inside_social_wrap d-flex"
                        : "col-6 inside_social_wrap d-flex"
                    }`}
                  >
                    <img
                      className="mt-3"
                      width={80}
                      height={80}
                      key={index}
                      src={images[index]}
                      alt={`Image ${index + 1}`}
                    />
                    <div
                      className={` ${
                        isMobileView
                          ? "line-height-35 font-weight-400 font-size-20 awsTitle mt-5 mx-2 text-uppercase  "
                          : " partnerListTitle awsTitle mt-5 mx-4 text-uppercase "
                      }`}
                    >
                      {val.name}
                    </div>
                  </div>
                  <div className={` ${isMobileView ? "col-12 mb-2" : "col-6"}`}>
                    <div className="des-style awsDescription text-start p-3  ">
                      {val.des}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <WorkingWithUs data={data} />

      <div className="awsWorking">
        <div
          className={` ${
            isMobileView
              ? "partner_container w-100"
              : "partner_container w-100 d-flex tellUsAboutContainer"
          }`}
        >
          <div className="textContainer">
            <div
              className={` ${
                isMobileView
                  ? `tellUsAboutHeading font-size-20 ${
                      locale !== "sv" && "capitalize"
                    } m-0 line-height-25`
                  : `tellUsAboutHeading ${locale !== "sv" && "capitalize"}`
              }`}
            >
              {data.WorkingSection.footer.heading}
            </div>

            <div
              className={` ${
                isMobileView
                  ? `tellUsAboutDescription ${
                      locale !== "sv" && "capitalize"
                    } m-0`
                  : `tellUsAboutDescription ${locale !== "sv" && "capitalize"}`
              }`}
            >
              {data.WorkingSection.footer.text}
            </div>
          </div>

          <div
            className={` ${
              isMobileView
                ? "startingCloud p-0 d-flex col-12"
                : "startingCloud p-0 d-flex align-items-center mt-4"
            }`}
          >
            <input
              type="text"
              name="reply_to"
              className="startingCloudInput"
              placeholder={data.WorkingSection.footer.email}
            />
            <div className="opsBtnwrap mx-2">
              <Link className="opsBtnlink" to={`/contact-us/`}>
                <p>{data.WorkingSection.footer.btn}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="footer-icon relative-icon"
        style={{
          backgroundImage: `url(${
            require("../../images/footer-icon.svg").default
          })`,
        }}
      ></div>
    </div>
  );
};

export default WhyAws;
