import { Link } from "gatsby";
import React, { useState, useEffect } from "react";

import aws from "../../images/aws-background.svg";
function OpsioAWS({ data, locale, isMobileView }) {
  return (
    <div className="partnerHotspot" id="opsio-aws">
      <div className={` ${isMobileView ? "p-0 opsioAws" : " opsioAws "}`}>
        <img src={aws} className="pb-5 w-100" alt="aws" />
        <div className="global-margin">
          <div
            className={` ${
              isMobileView
                ? "awsTitle text-uppercase text-center "
                : "awsTitle text-uppercase my-4 "
            }`}
          >
            {data.title}
          </div>
          <div
            className={` ${
              isMobileView
                ? `awsHeading my-4 text-center ${
                    locale !== "sv" && "capitalize"
                  }`
                : `awsHeading my-4 ${locale !== "sv" && "capitalize"}`
            }`}
          >
            {data.heading}
          </div>
          <div
            className={` ${
              isMobileView
                ? `awsDescription my-4 ${
                    locale !== "sv" && "capitalize"
                  } text-center`
                : `awsDescription my-4 ${locale !== "sv" && "capitalize"}`
            }`}
          >
            {data.des1}
          </div>
          <div
            className={` ${
              isMobileView
                ? `awsDescription my-5 ${
                    locale !== "sv" && "capitalize"
                  } text-center`
                : `awsDescription my-5 ${locale !== "sv" && "capitalize"}`
            }`}
          >
            {data.des2}
          </div>
          <div
            className={` ${
              isMobileView
                ? "opsBtnwrap justify-content-center d-flex "
                : "opsBtnwrap pt-3"
            }`}
          >
            <Link
              to={`/successstories/Migration-Of-Et-Network-To-AWS/`}
              className="opsBtnlink"
            >
              <p>{data.btn}</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpsioAWS;
