import React from "react";

function Services({ data, isMobileView }) {
  return (
    <div id="services" className="about partnerHotspot">
      <div className="global-margin plain-bg">
        <div
          className={` ${
            isMobileView ? "awsTitle py-4  text-center" : "awsTitle mb-4 "
          }`}
        >
          {data.title}
        </div>
        <h2
          className={` ${
            isMobileView
              ? "awsHeading capitalize text-center"
              : "awsHeading capitalize"
          }`}
        >
          {data.heading}
        </h2>
        <div
          className={` ${isMobileView ? "aboutOptions mt-0" : "aboutOptions"}`}
        >
          {data.pills.map((pill, index) => (
            <div className="aboutOptionsColumn" key={index}>
              <span className="text-uppercase sevices  font-weight-700 font-size-20 line-height-35 p-1">
                {pill}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
